var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "footer" },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { order: "2", "order-lg": "0", cols: "12", lg: "6" } },
                [
                  _c("div", { staticClass: "block-footer" }, [
                    _c(
                      "h5",
                      { staticClass: "column-headline headline-about" },
                      [
                        _c("span", { staticClass: "text" }, [
                          _vm._v(
                            _vm._s(_vm.$t("components.footer.headline.about")) +
                              " " +
                              _vm._s(_vm.projectName)
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "footer-text" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("components.footer.content.about")) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "block-footer" }, [
                    _c("h5", { staticClass: "column-headline logo-headline" }, [
                      _c("span", { staticClass: "text" }, [
                        _vm._v(
                          _vm._s(_vm.$t("components.footer.headline.platform"))
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "footer-logos" }, [
                      _c(
                        "a",
                        {
                          staticClass: "logo",
                          attrs: {
                            href: _vm.customer.website,
                            target: "_blank",
                          },
                        },
                        [
                          _c("b-img", {
                            staticClass: "logo-customer",
                            attrs: { src: _vm.customer.logoFooter, fluid: "" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "logo",
                          attrs: {
                            href: "https://www.academic-hero.de/produkte/campus-match",
                            target: "_blank",
                          },
                        },
                        [
                          _c("b-img", {
                            attrs: {
                              src: "/img/BA-Logo-Horiz_WHT_Transp.svg",
                              fluid: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { order: "0", "order-lg": "1", cols: "12", lg: "3" } },
                [
                  _c("h5", { staticClass: "column-headline headline-links" }, [
                    _c("span", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(_vm.customer.name) +
                          " " +
                          _vm._s(_vm.$t("components.footer.headline.links"))
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "box-links" },
                    [
                      _c(
                        "b-link",
                        {
                          staticClass: "link-footer-s",
                          attrs: {
                            href: _vm.customer.website,
                            target: "_blank",
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "link-icon",
                            attrs: { icon: "link" },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "link-text" }, [
                            _vm._v(_vm._s(_vm.websiteDomain)),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.socialLinks.instagram
                        ? _c(
                            "b-link",
                            {
                              staticClass: "link-footer-s",
                              attrs: {
                                href: _vm.socialLinks.instagram,
                                target: "_blank",
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "link-icon",
                                attrs: { icon: ["fab", "instagram-square"] },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "link-text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.footer.content.links.instagram"
                                    )
                                  )
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.socialLinks.facebook
                        ? _c(
                            "b-link",
                            {
                              staticClass: "link-footer-s",
                              attrs: {
                                href: _vm.socialLinks.facebook,
                                target: "_blank",
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "link-icon",
                                attrs: { icon: ["fab", "facebook-square"] },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "link-text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.footer.content.links.facebook"
                                    )
                                  )
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.socialLinks.xing
                        ? _c(
                            "b-link",
                            {
                              staticClass: "link-footer-s",
                              attrs: {
                                href: _vm.socialLinks.xing,
                                target: "_blank",
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "link-icon",
                                attrs: { icon: ["fab", "xing-square"] },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "link-text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.footer.content.links.xing"
                                    )
                                  )
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.socialLinks.youtube
                        ? _c(
                            "b-link",
                            {
                              staticClass: "link-footer-s",
                              attrs: {
                                href: _vm.socialLinks.youtube,
                                target: "_blank",
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "link-icon",
                                attrs: { icon: ["fab", "youtube-square"] },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "link-text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.footer.content.links.youtube"
                                    )
                                  )
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.socialLinks.linkedin
                        ? _c(
                            "b-link",
                            {
                              staticClass: "link-footer-s",
                              attrs: {
                                href: _vm.socialLinks.linkedin,
                                target: "_blank",
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "link-icon",
                                attrs: { icon: ["fab", "linkedin"] },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "link-text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.footer.content.links.linkedin"
                                    )
                                  )
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { order: "1", "order-lg": "2", cols: "12", lg: "3" } },
                [
                  _c(
                    "h5",
                    { staticClass: "column-headline headline-contact" },
                    [
                      _c("span", { staticClass: "text" }, [
                        _vm._v(
                          _vm._s(_vm.$t("components.footer.headline.contact"))
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-link",
                    {
                      staticClass: "link-footer-s footer-contact",
                      attrs: { to: { name: "contact" } },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "link-icon",
                        attrs: { icon: "envelope" },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "link-text" }, [
                        _vm._v(
                          _vm._s(_vm.$t("components.footer.content.contact"))
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { order: "3", cols: "12", lg: "7" } },
                [
                  _c("h5", { staticClass: "column-headline d-lg-none" }, [
                    _c("span", { staticClass: "text" }, [
                      _vm._v(_vm._s(_vm.$t("components.footer.headline.misc"))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-nav",
                    { staticClass: "footer-links" },
                    [
                      _vm.staticPages.imprint
                        ? _c(
                            "b-nav-item",
                            { attrs: { to: { name: "impress" } } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.footer.content.misc.imprint"
                                  )
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.staticPages.privacy
                        ? _c(
                            "b-nav-item",
                            { attrs: { to: { name: "privacy" } } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.footer.content.misc.privacy"
                                  )
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.staticPages.terms
                        ? _c("b-nav-item", { attrs: { to: { name: "agb" } } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("components.footer.content.misc.terms")
                              )
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.staticPages.accessibility
                        ? _c(
                            "b-nav-item",
                            { attrs: { to: { name: "accessibility" } } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.footer.content.misc.accessibility"
                                  )
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                {
                  staticClass: "copyright",
                  attrs: { order: "4", cols: "12", lg: "5" },
                },
                [
                  _vm._v(
                    "\n        Copyright © " + _vm._s(_vm.copyrightYear) + " "
                  ),
                  _c("span", { staticClass: "copyright-company" }, [
                    _vm._v(_vm._s(_vm.customer.name)),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }