'use strict';

import CustomConfig from '@/config.json';

const DefaultConfig = {
  projectName: 'campusMatch',
  projectLogo: '/headerLogo.svg',
  customer: {
    name: 'Brain Appeal',
    email: 'info@brain-appeal.com',
    phone: '+49 621 437843-00',
    logo: '/Customer-Logo.svg',
    logoFooter: '/Customer-Logo.svg',
    website: 'https://www.brain-appeal.com/',
    address: {
      street: 'Wildbader Straße 9',
      zipcode: '68239',
      town: 'Mannheim',
    },
  },
  logoLink: 'https://www.brain-appeal.com',
  // Beware: object sub.properties must be set in config.json with all properties!
  socialLinks: {
    instagram: 'https://www.instagram.com/dhbwheilbronn/',
    facebook: 'https://www.facebook.com/dhbwheilbronn',
    youtube: 'https://www.youtube.com/user/dhbwheilbronn',
    linkedin: 'https://www.linkedin.com/school/dhbw-heilbronn/',
  },
  staticPages: {
    imprint: true,
    privacy: true,
    terms: true,
    accessibility: true,
    contact: false,
  },
  applicant: {
    enableMatches: false,
    studyYearUpdatedOn: '20. Oktober',
    studyInterestSearchCheckboxes: false,
    dashboardShowUserTip: true,
  },
  company: {
    // toggle visibility for company study offers; either 'all' or 'authenticated'
    studyOffersVisibility: 'all',
    studyInterestSearchCheckboxes: false,
    map: {
      enable: false,
      fitBounds: true,
      zoom: 6,
    },
    profileRedirectToUrlIfNotAuthenticated: null,
    dashboardShowUserTip: true,
    signupInfoEmail: 'info@brain-appeal.com',
    signupInfoUrl: '',
  },
  contactMap: {
    enable: true,
    zoom: 16,
    center: {
      latitude: 49.14657927931675,
      longitude: 9.218482499040109,
    },
    marker: {
      latitude: 49.14657927931675,
      longitude: 9.218482499040109,
    },
  },
  contactBackground: null,
  mathTestUrl: null,
  enableRegistrationVerification: true,
  enableRegistrationFormHash: true,
};
const Config = Object.assign({}, DefaultConfig, CustomConfig);
// Merge customer and company properties, so we don't have to override the whole configuration for these sections
if (CustomConfig.customer) {
  Config.customer = Object.assign({}, DefaultConfig.customer, CustomConfig.customer);
}
if (CustomConfig.company) {
  Config.company = Object.assign({}, DefaultConfig.company, CustomConfig.company);
}

export default Config;
